const encodedAndCleanFileName =(file_name) =>  { 
    if(file_name) { 
        let encodedFileName = encodeURI(file_name);
        let encodedAndCleanFileName = encodedFileName.replace(/#(?=\S)/g, '%23')
        return encodedAndCleanFileName;
    }
    return null;
}

const removeSingleInstance = (arr, value_to_remove) => {
    const index = arr.indexOf(value_to_remove);
    if (index !== -1) {
        arr.splice(index, 1);
    }
    return arr;
}
export default {
    encodedAndCleanFileName,
    removeSingleInstance
}
