<template>
    <CModal
        size="xl"
        :show.sync="modal_form"
        color="primary"
        :closeOnBackdrop="false"
    >
        <template #header>
            <h5> <font-awesome-icon icon="link"/> Upload File Via URL </h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click="modal_form = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>
        <template #body-wrapper>
            <CCardBody>
                <h5>Please fill-up the form.</h5>
                <br>
                <template v-if="modal_form">
                    <CRow>
                        <CCol lg="4" v-for="(element, index) in template.document_template_file_api.document_template_file_api_params" v-bind:key="index">
                            
                            <div v-if="element.field_id != null">
                                <CInput 
                                    v-if="element.field.field_type_id == 1"
                                    :disabled="document_found || is_loading"
                                    :label="element.field_custom_name ? element.field_custom_name : element.field.display_name"
                                    :type="element.type" 
                                    :placeholder="element.field_custom_name ? element.field_custom_name : element.field.display_name" 
                                    autocomplete="off"  
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-inp-error' : 'has-inp-success' : ''"
                                />
                                <div v-if="element.field.field_type_id == 2">
                                    <label> {{element.field_custom_name ? element.field_custom_name : element.field.display_name}}</label>
                                    <v-select 
                                        :placeholder="element.field_custom_name ? element.field_custom_name : element.field.display_name" 
                                        label="value"
                                        autocomplete="off"  
                                        :disabled="document_found || is_loading"
                                        :options="fields.length > 0 ? fields.find(obj => { return obj.id === element.field.id }).field_drop_down_values_data : []"
                                        v-tocapitalize
                                        :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-error' : 'has-success' : ''"
                                        v-model="file_via_url_field[index]"
                                        @input="file_via_url_field[index] = $event.value"
                                    />
                                </div>

                                <CInput 
                                    v-if="element.field.field_type_id == 3 || element.field.field_type_id == 8"
                                    :disabled="document_found || is_loading"
                                    :label="element.field_custom_name ? element.field_custom_name : element.field.display_name"
                                    :type="element.type" 
                                    v-numberonly
                                    :placeholder="element.field_custom_name ? element.field_custom_name : element.field.display_name" 
                                    autocomplete="off"  
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-inp-error' : 'has-inp-success' : ''"
                                />
    
                                <CInput 
                                    v-if="element.field.field_type_id == 4"
                                    :disabled="document_found || is_loading"
                                    :label="element.field_custom_name ? element.field_custom_name : element.field.display_name"
                                    :type="setType(element.field.field_type_id)" 
                                    :placeholder="element.field_custom_name ? element.field_custom_name : element.field.display_name" 
                                    autocomplete="off"  
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-inp-error' : 'has-inp-success' : ''"
                                />
                                 <CTextarea
                                    v-if="element.field.field_type_id == 5"
                                    :label="element.field_custom_name ? element.field_custom_name : element.field.display_name"
                                    :disabled="document_found || is_loading"
                                    :placeholder="element.field_custom_name ? element.field_custom_name : element.field.display_name" 
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area'"
                                />
    
                                <CInput 
                                    v-if="element.field.field_type_id == 6"
                                    :disabled="document_found || is_loading"
                                    :label="element.field_custom_name ? element.field_custom_name : element.field.display_name"
                                    :placeholder="selectedPlaceholders[index] || element.field_custom_name ? element.field_custom_name : element.field.display_name" 
                                    autocomplete="off"  
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-inp-error' : 'has-inp-success' : ''"
                                    type="text" 
                                    readonly 
                                >
                                    <template #append>
                                        <CButton color="primary" @click="openModal(index, element.field.id)" :disabled="document_found || is_loading">
                                            <font-awesome-icon icon="search"/>
                                        </CButton>
                                    </template>
                                </CInput>    
                            </div>

                            <div v-else>
                                <CInput 
                                    v-if="element.type == 'input'"
                                    :disabled="document_found || is_loading"
                                    :label="element.parameter"
                                    :type="element.type" 
                                    :placeholder="element.parameter" 
                                    autocomplete="off"  
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-inp-error' : 'has-inp-success' : ''"
                                />
                                <CTextarea
                                    :label="element.parameter"
                                    v-if="element.type == 'textarea'"
                                    :disabled="document_found || is_loading"
                                    :placeholder="element.parameter" 
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-model="file_via_url_field[index]"
                                    :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area'"
                                />
                            </div>

                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="4">
                            <CButton
                                class="float-left"  
                                :disabled="form_submitting || document_found || is_loading"
                                color="primary" 
                                @click="getDocumentfromURL()">
                                <font-awesome-icon 
                                    class="icon" :icon="!form_submitting ? 'file' : 'circle-notch'"
                                    :pulse="form_submitting"/> 
                                    Get Document
                            </CButton>
                        </CCol>
                        <CCol lg="8"></CCol>
                    </CRow>
                </template>
                <template v-if="document_found">
                    <br>
                    <CRow>
                        <CCol lg="8"/>
                        <CCol lg="2">
                            <h5 style="margin-top:7px;" class="float-right"> Page</h5>
                        </CCol>
                        <CCol lg="1">
                            <CInput
                                type="number" 
                                autocomplete="off"  
                                min="1" :max="numPages"
                                v-model.number="page"/>
                        </CCol>
                        <CCol lg="1">
                            <h5 style="margin-top:7px;" class="float-left"> / {{numPages}} </h5>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="1">
                            <CButton color="primary" style="margin-top: 500%;" @click="page--" :disabled="page == 1">
                                <font-awesome-icon icon="angle-double-left"/>
                            </CButton>
                        </CCol>
                        <CCol lg="10">
                            <Vue-Pdf
                                :page="page" 
                                @num-pages="numPages = $event" 
                                :src="fetch_document.file"
                            />
                        </CCol>
                        <CCol lg="1">
                            <CButton color="primary" style="margin-top: 500%;" @click="page++" :disabled="page==numPages">
                                <font-awesome-icon icon="angle-double-right"/>
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
                <br>
                <template v-if="document_found">
                    <CRow>
                        <CCol lg="8"></CCol>
                        <CCol lg="2">
                            <CButton 
                                @click="clearDocument()"
                                color="secondary" 
                                class="float-right">
                                <font-awesome-icon class="icon" icon="times" /> 
                                    Clear Document
                            </CButton>
                        </CCol>
                        <CCol lg="2" >
                            <CButton
                                @click="confirmDocument()"
                                color="primary" class="float-right">
                                <font-awesome-icon class="icon" icon="save" /> 
                                    Confirm Document
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
                <CModal :show.sync="input_search_modal" color="primary" :closeOnBackdrop="true" @close="input_search_modal=false">
                    <template #header>
                        <h5 class="modal-title"><font-awesome-icon icon="list-ul" /> List</h5>
                        <CButton class="pull-right" color="light" shape="pill" size="sm" @click="input_search_modal=false">
                            <font-awesome-icon icon="times" />
                        </CButton>
                    </template>
                    <template #body-wrapper>
                        <CCardBody>
                            <CDataTable :items="dropdown" :fields="dropdown_fields"
                                :table-filter="{ placeholder: 'Your text here.', label: 'Search:' }" :items-per-page="10"
                                items-per-page-select border sorter pagination  @row-clicked="selectedDropdownValue" :loading="input_search_table_loading">
                            </CDataTable>
                        </CCardBody>
                    </template>
                    <template #footer>
                        <CButton color="secondary" @click="input_search_modal=false">
                            Close
                        </CButton>
                    </template>
                </CModal>
            </CCardBody>
        </template>
        <template #footer>
            <hr hide>
        </template>
    </CModal>
</template>
<script>
export default {
    name: 'UploadFileViaURLModal',
    data() {
        return {
            page: 1,
            numPages: 0,
            
            can_upload_file_via_url: false,
            modal_form: false,

            form_submitting: false,
            document_found: false,
            file_via_url_field:[],
            fetch_document: null,
            fields:[],
            template: null,
            showModal: false,
            selectedPlaceholders: [],
            currentIndex: null,
            input_search_modal:false,
            dropdown: [],
            dropdown_fields: [
                { key: 'id', label: 'ID' },
                { key: 'value', label: 'Value' },
            ],
            input_search_table_loading:true,
            data_loading: false,
            is_loading:true,
        }
    },
    created() {
        // this.getFields();
    },
    mounted() {
        // this.getFields();
    },
    methods: {
        validateForm: function(){
            let required_fields = []
            for (let i = 0; i < this.template.document_template_file_api.document_template_file_api_params.length; i++) {
                const element = this.template.document_template_file_api.document_template_file_api_params[i];
                if(element.is_required ==  '1' && !this.file_via_url_field[i]){
                    required_fields.push(element.parameter);
                }
            }

            if( required_fields.length > 0 ) {
                return false
            }
            return true;
        },
        getDocumentfromURL: function () {
            if(!this.validateForm()) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Please fill-up the required fields.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }


            this.form_submitting = true
            this.is_loading = true;

            var columns = this.template.document_template_file_api.document_template_file_api_params //new 
            var rows = this.file_via_url_field

            var parameters = columns.reduce(function (parameters, field, index) {
                var parameterKey = field.field && field.field.parameter_name ? field.field.parameter_name : field.parameter;
                    parameters[parameterKey] = rows[index] ? rows[index] : null;
                    return parameters;
            }, {});


            var field_id = columns.reduce(function (field_id, field, index) {
                field_id[field.field_id] = rows[index] ? rows[index] : null;
                    return field_id;
                }, {})

            let form = {
                document_template_id: this.template.dt_id,
                document_template_file_api_id: this.template.document_template_file_api.id,
                parameters: parameters,
                field_id:field_id
            }

            this.$Progress.start()
            axios.post('/drs/document-template-file-api/get-document', form, {validateStatus: () => true})
                .then(response => {
                    this.$Progress.finish()
                    if ( response.status == 200 ) {
                        this.form_submitting = false
                        this.document_found = true;
                        this.fetch_document = response.data.data
                        this.fetch_document.file = `${this.$backend_url_with_auth}/file-viewer/tmp/${this.fetch_document.path}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'success',
                            title: 'Document Found.',
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        return; 
                    }
                    this.form_submitting = false
                    this.is_loading = false;
                })
        },
        clearDocument: function () {
            this.fetch_document = null
            this.document_found = false;
            this.is_loading = false;
        },
        confirmDocument: function (){
            let file = {
                size: this.fetch_document.file_size,
                name: this.fetch_document.reference_number+'.pdf',
                type: "application/pdf",
                url: `${this.$backend_url_with_auth}/file-viewer/tmp/${this.fetch_document.path}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                response_url: this.fetch_document.path

            }
            let object = {
                file: file,
                reference_number: this.fetch_document.reference_number
            }
            this.modal_form = false
            this.$emit('documentFoundViaURLConfirmed', object)
        },
        getFields: function() {
            this.data_loading = true;
            this.is_loading = true;
            this.$Progress.start();
            axios.get('/form-managements/field/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200)  {
                    this.fields = response.data.data
                    if(this.fields.length > 0) {
                        let empty_block = {
                            id: null,
                            display_name: 'Empty Block',
                            parameter_name: 'empty_block',
                            field_type: {
                                name: 'n/a'
                            },
                            source_type: 'none',
                            validation: null
                        }
                        this.fields.push(empty_block)
                    }
                    this.$Progress.finish();
                }
                this.is_loading = false;
                this.data_loading = true;

            })
        },
        setType: function(field_id){
            if(field_id == 1){
                return 'text';
            }else if(field_id == 2){
                return 'dropdown';
            }else if(field_id == 3){
                return 'number';
            }else if(field_id == 4){
                return 'date';
            }else if(field_id == 5){
                return 'textarea';
            }
            else if(field_id == 6){
                return 'input_search';
            }
            else if(field_id == 7){
                return 'label';
            }
            else if(field_id == 8){
                return 'number_format';
            }
        },
        openModal(index, selectedFieldId) {
            this.currentIndex = index;
            this.getFieldDropdownValues(selectedFieldId);
            this.input_search_modal = true;
            
        },
        getFieldDropdownValues: function(selectedFieldId) {
                this.$Progress.start()
                this.input_search_table_loading = true;
                axios.post(`/form-managements/form/get-field-dropdown-values/${selectedFieldId}`, {validateStatus: () => true})
                .then(response => {
                    if(response.status == 200) {
                        this.dropdown = []
                        for(let i = 0; i < response.data.length; i++) {
                            let option = {
                                id: response.data[i].id,
                                value: response.data[i].value,
                            }
                            this.dropdown.push(option)
                        }
                        this.$Progress.finish()
                        this.input_search_table_loading = false;
                    }
                })
            },
        selectedDropdownValue: function(item, index, column, event){
            this.$set(this.file_via_url_field, this.currentIndex, item.value);
            this.$set(this.selectedPlaceholders, this.currentIndex, item.value);
            this.input_search_modal = false;
        }

    },
    watch: {
        modal_form: function (value){
            if(!value) {
                this.file_via_url_field = []
                this.document_found = false
                this.fetch_document= null
                this.form_submitting = false
            }
        }
        
    },
     
}
</script>